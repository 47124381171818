import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar, checkSessionVar, checkSessionVarWithData} from './sessionStorage_controller'
import {set_locale} from './localize_controller'

import {unblockBody} from './blockUI_controller'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'
import 'slick-carousel/slick/slick.min'
import {create_array_for_product} from "./b2b_controller";

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim ());
}

require ("select2/dist/css/select2")
require ("select2-bootstrap-theme/dist/select2-bootstrap")


export default class extends Controller {

    initialize () {
        set_locale ().then (
            locale => {
                const btn_assistant_line = $ ('#btn_assistant_line');
                if (location.pathname.includes ('help_me')) {
                    btn_assistant_line[0].style.display = 'none';
                }
                if (sessionStorage.hasOwnProperty ('need_clean')) {
                    if (sessionStorage.getItem ('need_clean') === "true" && sessionStorage.getItem ('inPayProccess') === "true" && location.pathname.includes ("/payment/return")) {
                        setSessionVar ('', '', 'clear', false);
                    }
                }

                //Disabled input
                $ ('#date_collect').attr ('disabled', true);
                $ ('#time_collect').attr ('disabled', true);
                $ ('#date_delivery').attr ('disabled', true);
                $ ('#time_delivery').attr ('disabled', true);

                unblockBody ();
            })
    }

    setSessionKey (e) {
        setSessionVar (e.target.dataset.name, e.target.value, 'add', e.target.dataset.isdate, false).then (
            data => {
                create_array_for_product (e.target.dataset.name, data.value, 'add');
                if (e.target.dataset.hasOwnProperty ('samedate')) {
                    if (e.target.dataset.samedate === 'true') {
                        let add_date = parseInt (e.target.dataset.adddate) || 1;
                        setSessionVar (e.target.dataset.datename, e.target.value, 'add', e.target.dataset.isdate, false, e.target.dataset.samedate || false, add_date).then (
                            same_date_data => {
                                create_array_for_product (e.target.dataset.datename, same_date_data.value, 'add');
                            })
                    }
                }

            }
        );
    }

    print (e) {
        set_locale ().then (
            locale => {
                location.href = location.origin + `/${locale.locale}/histories/${e.target.dataset.history}.pdf`

            }
        )
    }

    hide_reservation () {
        let r = $ ('#reservation');
        r.removeClass ('show_reservation');
        r.addClass ('hidde_reservation')
    }

    show_hide_element (e) {
        let hide = $ ('#' + e.target.dataset.hide);
        let show = $ ('#' + e.target.dataset.show);

        hide.addClass ('ocultar');
        show.removeClass ('ocultar');
    }
}