import {Controller} from "stimulus"
import lightGallery from 'lightgallery';
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-pager.css'
import 'lightgallery/css/lg-thumbnail.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-relative-caption.css'
// Plugins
import Thumbnail from 'lightgallery/plugins/thumbnail'
import Zoom from 'lightgallery/plugins/zoom'
import Pager from 'lightgallery/plugins/pager'
import RelativeCaption from 'lightgallery/plugins/relativeCaption'



export default class extends Controller {

    connect() {
       lightGallery(document.getElementById(this.element.id),{
           plugins: [Zoom, Thumbnail,Pager,RelativeCaption],
           mode: 'lg-fade',
           speed: 500,
       })
    }
}