import {Controller} from "stimulus"
import $ from 'jquery';
import {check_availability} from "./formAction_controller";

let request_send = (params) => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/request-reservation`,
        data: params,
        type: 'POST',
        success: function (data) {
            resolve(data);
        }
    })
})
let send = (params) => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/reservation`,
        data: params,
        type: 'POST',
        success: function (data) {
            resolve(data);
        }
    })
})

export default class extends Controller {

    initialize() {

        this.validate = false;
        this.show_agency_fields = this.element.dataset.showFields;
        this.next_click = document.querySelectorAll(".next_button");
        this.main_form = document.querySelectorAll(".main");
        this.step_list = document.querySelectorAll(".progress-bar li");
        this.num = document.querySelector(".step-number");
        this.formnumber = 0;
        this.back_click = document.querySelectorAll(".back_button");
        this.username = document.querySelector("#user_name");
        this.submit_click = document.querySelectorAll(".submit_button");
        this.request_submit_click = document.querySelectorAll(".request_submit_button");
        this.submit_back = document.querySelectorAll(".submit_back");
        this.step_num_content = document.querySelectorAll(".step-number-content");
    }

    connect() {
        let context = this;
        this.next_click.forEach(function (next_click_form) {
            next_click_form.addEventListener('click', function () {
                if (!context.validateform()) {
                    return false
                }
                context.formnumber++;
                context.updateform();
                context.progress_forward();
                context.contentchange();
            });
        });
        this.back_click.forEach(function (back_click_form) {
            back_click_form.addEventListener('click', function () {
                context.formnumber--;
                context.updateform();
                context.progress_backward();
                context.contentchange();
            });
        });
        this.submit_click.forEach(function (submit_click_form) {
            submit_click_form.addEventListener('click', function () {
                if (!context.validateform()) {
                    return false
                }

                if (submit_click_form.dataset.hasOwnProperty('form')) {
                    submit_click_form.innerHTML = "<span class='fa fa-spinner fa-spin fa-2x'></span>";
                    submit_click_form.setAttribute('disabled',"disabled");
                    let form = $(`#${submit_click_form.dataset.form}`);
                    let input = form.find('input[type="text"]');
                    let input_hidden = form.find('input[type="hidden"]');
                    let checkbox = form.find('input[type="checkbox"]');
                    let select = form.find('select');
                    let data = {};

                    if(input.length > 0){
                        input.map((index,elem) => {
                            if(elem.name.length > 0){
                                data[elem.name] = elem.value
                            }
                        })
                    }
                    if(input_hidden.length > 0){
                        input_hidden.map((index,elem) => {
                            if(elem.name.length > 0){
                                data[elem.name] = elem.value
                            }
                        })
                    }
                    if(checkbox.length > 0){
                        checkbox.map((index,elem) => {
                            if(elem.name.length > 0){
                                data[elem.name] = elem.checked
                            }
                        })
                    }
                    if(select.length > 0){
                        select.map((index,elem) => {
                            if(elem.name.length > 0){
                                data[elem.name] = elem.value
                            }
                        })
                    }

                    send(data).then(r => {
                        let check = $(`#check_${r.success}`);
                        let not_check = $(`#check_${!r.success}`);
                        let check_title = $(`#check_title`);
                        let check_text = $(`#check_text`);

                        not_check.addClass('d-none');
                        check.removeClass('d-none');
                        check_title[0].innerHTML = r.code;
                        check_text[0].innerHTML = r.message

                        context.formnumber++;
                        context.updateform();

                        if(!r.success){
                            context.submit_back.forEach(function (submit_back_form) {
                                submit_back_form.classList.remove('ocultar');
                            });
                        }else{
                            submit_click_form.innerHTML = "<span class=''>Reservar</span>";
                            submit_click_form.removeAttribute('disabled');
                            context.submit_back.forEach(function (submit_back_form) {
                                submit_back_form.classList.add('ocultar');
                            });
                        }
                    });

                } else {
                    context.formnumber++;
                    context.updateform();
                }

            });
        });
        this.request_submit_click.forEach(function (request_submit_click_form) {
            request_submit_click_form.addEventListener('click', function () {
                if (!context.validateform()) {
                    return false
                }

                if (request_submit_click_form.dataset.hasOwnProperty('form')) {
                    let form = $(`#${request_submit_click_form.dataset.form}`);
                    let input = form.find('input[type="text"]');
                    let input_hidden = form.find('input[type="hidden"]');
                    let checkbox = form.find('input[type="checkbox"]');
                    let select = form.find('select');
                    let data = {};

                    if(input.length > 0){
                        input.map((index,elem) => {
                            if(elem.name.length > 0){
                                data[elem.name] = elem.value
                            }
                        })
                    }
                    if(input_hidden.length > 0){
                        input_hidden.map((index,elem) => {
                            if(elem.name.length > 0){
                                data[elem.name] = elem.value
                            }
                        })
                    }
                    if(checkbox.length > 0){
                        checkbox.map((index,elem) => {
                            if(elem.name.length > 0){

                                data[elem.name] = elem.checked
                            }
                        })
                    }
                    if(select.length > 0){
                        select.map((index,elem) => {
                            if(elem.name.length > 0){
                                data[elem.name] = elem.value
                            }
                        })
                    }

                    request_send(data).then(r => {
                        let check = $(`#check_${r.success}`);
                        let not_check = $(`#check_${!r.success}`);
                        let check_title = $(`#check_title`);
                        let check_text = $(`#check_text`);

                        not_check.addClass('d-none');
                        check.removeClass('d-none');
                        check_title[0].innerHTML = r.code;
                        check_text[0].innerHTML = r.message

                        context.formnumber++;
                        context.updateform();

                        if(!r.success){
                            context.submit_back.forEach(function (submit_back_form) {
                                submit_back_form.classList.remove('ocultar');
                            });
                        }else{
                            context.submit_back.forEach(function (submit_back_form) {
                                submit_back_form.classList.add('ocultar');
                            });
                        }
                    });

                } else {
                    context.formnumber++;
                    context.updateform();
                }

            });
        });
        this.submit_back.forEach(function (submit_back_form) {
            submit_back_form.addEventListener('click', function () {
                context.formnumber--;
                context.updateform();
            });
        });

    }

    updateform() {
        this.main_form.forEach(function (mainform_number) {
            mainform_number.classList.remove('active');
        })
        this.main_form[this.formnumber].classList.add('active');
    }

    progress_forward() {
        this.num.innerHTML = this.formnumber + 1;
        this.step_list[this.formnumber].classList.add('active');
    }

    progress_backward() {
        let form_num = this.formnumber + 1;
        this.step_list[form_num].classList.remove('active');
        this.num.innerHTML = form_num;
    }

    contentchange() {
        this.step_num_content.forEach(function (content) {
            content.classList.remove('active');
            content.classList.add('d-none');
        });
        this.step_num_content[this.formnumber].classList.add('active');
        this.step_num_content[this.formnumber].classList.remove('d-none');
    }

    validateform() {
        let validate = true;
        let validate_inputs = document.querySelectorAll(".main.active input");
        let validate_select = document.querySelectorAll(".main.active select");
        if (validate_inputs.length > 0) {
            validate_inputs.forEach(function (vaildate_input) {
                vaildate_input.classList.remove('warning');
                if (vaildate_input.classList.contains('required')) {
                    if (vaildate_input.value.length === 0) {
                        validate = false;
                        vaildate_input.classList.add('warning');
                    }
                }
            });
        }

        if (validate_select.length > 0) {
            validate_select.forEach(function (validate_select) {
                validate_select.classList.remove('warning');
                if (validate_select.classList.contains('required')) {
                    if (validate_select.value.length === 0) {
                        validate = false;
                        validate_select.classList.add('warning');
                    }
                }
            });
        }
        return validate;

    }

    checkAvailability(e){
        let context = this;
        if(this.validateform()){
            let field = e.target.dataset;
            let params = {};
            if(field.hasOwnProperty('check')){
                let check = $(`#${field.check}`).find('.availability');
                let authenticity_token = $(`input[name="authenticity_token"]`).val();
                if(check.length > 0){
                    check.map((index,elem) => {
                        if(elem.name !== undefined && elem.name.length > 0){
                            params[elem.name] = elem.value;
                        }
                    })
                    params["authenticity_token"] = authenticity_token;
                    check_availability(params, e.target).then(r => {
                        $(e.target).removeAttr('disabled');
                        e.target.innerHTML = 'Siguiente';

                        if(r.success){
                            toastr.success(r.message);
                            context.formnumber++;
                            context.updateform();
                            context.progress_forward();
                            context.contentchange();
                        }else{
                            toastr.warning(r.message);
                        }
                    });
                }
            }else{
                toastr.warning('Faltan parametros para validar');
            }
        }
    }

}

