import {Controller} from "stimulus"
import $ from 'jquery';
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";



export default class extends Controller {

    connect() {
        flatpickr(`#${this.element.id}`, {
            enableTime: true,
            noCalendar: true,
            dateFormat: "h:i K",
            disableMobile: "true",
            locale: Spanish
        });
    }
}