import {Controller} from "stimulus"
import $ from 'jquery';

export default class extends Controller {

    connect() {
      this.clone_area = $(`#${this.element.dataset.clone}`);
      this.number_of_field = this.clone_area.find('.clone');
      if(this.clone_area == null) {
          toastr.error('Season Range Day: Falta el parametro "clone".')
      }else{
       if(this.number_of_field.length === 0){
           this.clone_area.append(this.tpl_season(this.number_of_field.length));
       }
      }
    }

    count_elements(){
         this.number_of_field = this.clone_area.find('.clone');
    }
    add(){
        this.count_elements();
        this.clone_area.append(this.tpl_season(this.number_of_field.length));
    }

    tpl_season(index){

        return `<div class="mb-3 row clone" id="clone_${index}">
            <div class="col-md-4">
              <label class="mb-1" for="season_day_range_from">
               Desde
              </label>
              <input class="form-control input-sm" min="1" step="1" type="number" name="season_day_range[${index}][from]" id="season_day_range_from_${index}">
            </div>
            <div class="col-md-4">
              <label class="mb-1" for="season_day_range_to">
                Hasta
</label>              <input class="form-control" min="1" step="1" type="number" name="season_day_range[${index}][to]" id="season_day_range_to_${index}">
            </div>
            <div class="col-md-3">
              <label class="mb-1" for="season_day_range_price">
                Precio
</label>              <input class="form-control" type="text" name="season_day_range[${index}][price]" id="season_day_range_price_${index}">
            </div>
            <div class="col-md-1">
            <button type="button" class="btn btn-danger" onclick="$('#clone_${index}').remove();" style="position: absolute;bottom: 5px;"><span class="icon-trash"></span> </button>
             </div>
          </div>`
    }
}