import {Controller} from "stimulus"
import $ from 'jquery';
import 'slick-carousel'

export default class extends Controller {

    connect() {
        $(this.element).slick({
            dots: true,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });
    }
}