import {Controller} from "stimulus"
import $ from 'jquery';
import Swal from 'sweetalert2/dist/sweetalert2'
// import {get_popup} from './popup_controller'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'
import {set_locale} from './localize_controller'
import bootbox from "bootbox";

export let validate_emails = (email) => new Promise((resolve, reject) => {
    let success = false;
    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //Se muestra un texto a modo de ejemplo, luego va a ser un icono

    if (emailRegex.test(email)) {
        success = true;
    }

    resolve(success);
})
export let verification = (not_authenticated = true) => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/config/has_autenticated_or_invalid_email`,
        data: {
        },
        type: "GET",
        success: function (data) {
            if (data.authenticated) {
                if (!data.valid_email) {
                    if (data.valid_email_token) {

                    } else {

                    }

                } else {
                    resolve({
                        validate: true
                    })
                }
            } else {
                if (not_authenticated) {

                }else{
                    resolve({
                        validate: true
                    })
                }
            }
        }
    });
})

export let get_authenticated_user_data = () => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/${sessionStorage.getItem('locale')}/authenticated_user`,
        data: {},
        type: 'GET',
        success: function (user) {
            if (user.success) {
                resolve(user);
            } else {
                if(user.authenticated_by_book){
                    $('#link_login_modal').trigger('click');
                }else{
                    let dialog_user_data = bootbox.dialog({
                        title: 'Datos del cliente',
                        message: `<p class='text-danger small'>(*) Campos obligatorios</p><br>
                    <div id="user_form" class="row" data-controller="country">
                        <div class="col-12 mb-2">
                         <input id="user_username" type="text" placeholder="* Nombre de cliente" class="form-control required"> 
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_lastname" type="text" placeholder="* Apellidos de cliente" class="form-control required"> 
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_email"  type="email" placeholder="* Correo Electrónico" class="form-control required">
                         <small class="text-danger"> Para enviar la notificación y el voucher de la compra.</small> 
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_passport" type="text" placeholder="* Pasaporte" class="form-control required"> 
                        </div>
                        <div class="col-12 mb-2">
                         <select id="user_country_code" class="form-control required"> </select>
                         <small class="text-danger"> Norma ISO de dos caracteres.</small>
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_city" type="text" placeholder="* Ciudad" class="form-control required"> 
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_address" type="text" placeholder="* Dirección" class="form-control required"> 
                        </div>
                    </div>
                              `,
                        size: 'small',
                        buttons: {
                            cancel: {
                                label: "Cancelar",
                                className: 'btn-secondary'
                            },
                            ok: {
                                label: "Confirmar datos",
                                className: 'btn-success',
                                callback: function (e) {
                                    let count = 0;
                                    let form_required = $('#user_form').find('.required');
                                    form_required.map((index, element) => {
                                        if (element.value.isEmpty()) {
                                            count += 1;
                                            $(element)[0].classList.remove('is-valid');
                                            $(element)[0].classList.add('is-invalid');
                                        } else {
                                            $(element)[0].classList.add('is-valid');
                                            $(element)[0].classList.remove('is-invalid');
                                        }
                                    });

                                    validate_email(document.getElementById('user_email').value).then(
                                        success => {
                                            if (success) {
                                                if (count > 0) {
                                                    return false;
                                                } else {
                                                    toastr.info('Validando datos del cliente');
                                                    $('button.bootbox-close-button').trigger('click');
                                                    resolve({
                                                        username: document.getElementById('user_username').value,
                                                        lastname: document.getElementById('user_lastname').value,
                                                        email: document.getElementById('user_email').value,
                                                        passport: document.getElementById('user_passport').value,
                                                        country_code: document.getElementById('user_country_code').value.toLowerCase(),
                                                        country_city: document.getElementById('user_city').value,
                                                        address: document.getElementById('user_address').value
                                                    })


                                                }
                                            } else {
                                                let email_user_form = document.getElementById('user_email');
                                                email_user_form.classList.add('is-invalid');
                                                email_user_form.classList.remove('is-valid');
                                                return false;
                                            }
                                        })

                                    return false;

                                }
                            }
                        }
                    })
                    setTimeout(()=>{
                        $('.bootbox.modal')[0].removeAttribute('tabindex');
                    },500)
                    ;
                }
            }
        }
    })
})

export default class extends Controller {

    initialize() {
        set_locale().then(
            locale => {
                verification(false).then();
            })

        let url = location.href;
        let url_get_login= url.split ('login=true');

        if(url_get_login.length > 1){
            let modal_login = $ ('#loginModal');
            let options = {
                show: true,
                focus: true,
                keyboard: false,
                backdrop: 'static'

            }

            modal_login.modal (options);
        }
    }
}