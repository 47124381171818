import {Controller} from "stimulus"
import $ from 'jquery';
import {checkSessionVarWithData} from './sessionStorage_controller'

require("block-ui/jquery.blockUI")


export let blockBody = () => {
    $.blockUI({
        theme: false,
        css: {
            padding: 0,
            margin: 0,
            width: '100%',
            top: '0',
            left: '0',
            textAlign: 'center',
            color: '#000',
            border: 'none',
            backgroundColor: '#fff',
            cursor: 'none'
        },
        overlayCSS: {
            backgroundColor: '#ffffff',
            opacity: '1'
        },
        fadeIn: 500,
        message: `<span class="fa fa-spinner fa-pulse fa-3x fa-fw"></span>`
    })
}
// <div className="road">
//     <div className="car">
//         <div className="lights"></div>
//         <span>
//                                 <b></b>
//                                 <i></i>
//                             </span>
//     </div>
// </div>

export let unblockBody = () => {
    $.unblockUI({fadeOut: 1000});
}

export let blockElementsById = (id, msg, background_text = 'rgba(0,0,0,0)', color_text = '#0056A3', background_overlay = '#FFFFFF') => {
    let block_element = $('#' + id);

    block_element.block({
        message: `${msg}`,
        css: {
            border: 'none',
            backgroundColor: `${background_text}`,
            color: `${color_text}`,
            backdropFilter: 'blur(2px)',
            borderRadius: '0 10px 10px 0'
        },
        overlayCSS: {
            backgroundColor: `${background_overlay}`,
            opacity: 1,
            cursor: 'wait',
            backdropFilter: 'blur(2px)',
            borderRadius: '5px',
            zIndex: 9999
        },
    });
}

export let unBlockElementById = (id) => {
    let block_element = $('#' + id);
    block_element.unblock();
}

export default class extends Controller {

    connect() {

        blockBody();
    }

    blockElement(e) {
        blockElementsById(e.target.dataset.block, e.target.dataset.blockMessage);

    }
}