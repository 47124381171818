import consumer from "./consumer"
document.addEventListener('turbo:load', () => {
  if (!location.pathname.includes('/admin')) {
    let body = document.getElementById('user_notification_id');
    if (body !== null) {
      let user_id = body.dataset.userId;
      if (user_id !== undefined) {
        consumer.subscriptions.create({channel: "UserChannel", user_id: user_id}, {
          connected() {
          },

          disconnected() {
            // Called when the subscription has been terminated by the server
          },

          received(data) {
            switch (data.type) {
              case 'toastr':
                toastr[data.color](data.message);
                break;
            }
            // Called when there's incoming data on the websocket for this channel
          }
        });
      }
    }
  }
});
